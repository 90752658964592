/* eslint-disable no-unused-vars */
import './App.scss';
import { About, Header, Projects, Skills } from './container/index';
import { Navbar } from './components';


function App() {

  return (
    <div className="app">
      <Navbar />
      <Header />
      <About />
      <Projects /> 
      <Skills />
    </div>
    );
}

export default App;
